import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProviderTaxDetails, ProviderTaxDetailsWithTrainingAttestation } from 'src/app/services/models/provider-tax-details';
import { ReportsService } from 'src/app/services/reports.service';
import { SessionService } from 'src/app/services/session.service';
import { UiUtility } from 'src/app/utils/ui-utility';

@Component({
  selector: 'app-attestation-provider-training-annual',
  templateUrl: './attestation-provider-training-annual.component.html',
  styleUrl: './attestation-provider-training-annual.component.scss'
})
export class AttestationProviderTrainingAnnualComponent implements OnInit {
  private userCollectionSubscription: Subscription;
  private sortColumn: string;
  private sortAscending: boolean;

  public displayProviderAttestationTraining: boolean;
  public displayTins: ProviderTaxDetailsWithTrainingAttestation[];
  public tins: ProviderTaxDetailsWithTrainingAttestation[];
  public selectedTin: string;
  public selectedTaxDetail: ProviderTaxDetailsWithTrainingAttestation;
  public totalRecords: number;
  public displayMessage = false;
  public isEnterpriseUser = false;

  constructor(
    private sessionService: SessionService,
    private reportService: ReportsService
  ) {
    this.sortColumn = 'name';
    this.sortAscending = true;
  }

  ngOnInit(): void {
    this.isEnterpriseUser = this.sessionService.currentPermission.isEnterpriseAdmin || this.sessionService.currentPermission.isEnterpriseUser
    if(this.isEnterpriseUser ) {
      // Do nothing, let internal user to search
    }
    else {
      const currentCollectionId = this.sessionService.currentPermission.providerCollectionID;
      this.searchProviders(currentCollectionId);
    }
  }

  ngOnDestroy(): void {
    if (this.userCollectionSubscription != null) {
      this.userCollectionSubscription.unsubscribe();
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {
    this.tins.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  
  public onSearch(search: any) : void {
    this.selectedTin = '';

    if (search && !UiUtility.isNullUndefinedOrEmpty(search.providerId)) {
      if(this.isEnterpriseUser) {
        this.searchProviders(0, search.providerId);
      }
      else {
        this.displayTins = this.tins
          .filter(p => p.providerTaxId.toLowerCase().indexOf(search.providerId.trim().toLowerCase()) >= 0);
      }
    }
    else {
      this.displayTins = this.tins.slice();
    }
  }

  public onTinSelected(tin: ProviderTaxDetailsWithTrainingAttestation, isRadioButton: boolean = false) {
    if (tin.trainingAttestationStatusDisplay === 'Submitted' && isRadioButton) {
      return; //disable radio button
    }
    this.selectedTaxDetail = tin;
    this.displayProviderAttestationTraining = true;
  }

  public onProcessAttestation(event: any) {
    console.log('emit event value', event);
    this.displayProviderAttestationTraining = false;
    if (event != null && event !== 'cancel') {
      this.selectedTaxDetail = event;

      if (!this.isEnterpriseUser && this.selectedTaxDetail.attendees.length > 0) {
        this.selectedTaxDetail.attendees = "*****";
      }
    }
  }

  private searchProviders(collectionId: number, providerTaxId: string = null) {
    this.userCollectionSubscription = this.reportService.getProvidersForTrainingAttestationAnnual(collectionId, providerTaxId).subscribe(result=>{
      this.tins = result !== null ? result : [];
      this.totalRecords = this.tins.length;

      if(this.tins && this.tins.length) {
        this.tins.forEach(t => {
          t.trainingAttestationStatusDisplay = this.reportService.providerTrainingStatusMapping(t.trainingAttestationStatus);
        });
        this.displayTins = this.tins.slice();
      }
      this.displayMessage = this.tins.length === 0;
      let param: any = {
        "field": "providerTaxName",
        "order": 1
      }
      this.onSort(param)
    });
  }
}
