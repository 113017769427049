
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiUtility } from '../../utils/ui-utility';

@Component({
  selector: 'app-reports-type',
  templateUrl: './reports-type.component.html',
  styleUrls: ['./reports-type.component.scss']
})
export class ReportsTypeComponent implements OnInit{
  private paramsviaState: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute) 
  { 
    if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state)
      this.paramsviaState = this.router.getCurrentNavigation().extras.state;
  }
  
  @Input() reportType: string;

  public get showDHCSBLS() : boolean{
    var lastdate = new Date("2023-08-01").valueOf();
    var todayDate = Date.now().valueOf();
    
    return (todayDate < lastdate);
  }

  ngOnInit() {
    if(this.paramsviaState) {
      this.extractStateParam(this.paramsviaState);
    }    
    else {
      this.route.params.subscribe(value => {
        // If suspenseID get user and assigne to selected user
        this.extractStateParam(value);        
      });  
    }
  }

  public onChange() {
    this.router.navigate(['/reports/' + this.reportType]);
  }

  public redirectToAttestation() {
    this.router.navigate(['/reports/attestationhome'])
  }

  public onContinue(){
    
  }

  private extractStateParam(params) {
    if (params && params.rname) {
      const reportName = params.rname;
      if (!UiUtility.isNullOrUndefined(reportName)) {
        this.reportType = reportName;
        this.onChange();
      }
    }
  }
}
