<div class="reports-bhi-report-type">
    <app-reports-type [reportType]="'bhi'"></app-reports-type>
</div>
<div class="reports-bhi-container">
  <div class="report-alert">
    <app-alert-info subjectMessage=" " uniqueID="reportsbhi" contentMessage="Narrow your search using the known provider name or ID."></app-alert-info>
  </div>
  <div class="report-search">
    <app-reports-provider-search (onProviderSearch)="onSearch($event)"></app-reports-provider-search>
  </div>
  <div class="report-parameter">
    <p-table tableStyleClass="table-data" #providerList
    [value]="displayProviders"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)"
    [styleClass]="'p-datatable-striped p-datatable-lg'"
    >
      <ng-template pTemplate="header">
        <tr>
          <th width="5%"></th>
          <th width="40%" pSortableColumn="name">Name<div class="{{sortClass('name')}}"></div></th>
          <th width="20%" pSortableColumn="calProviderID" class="header-provider-column">Provider ID<div class="{{sortClass('calProviderID')}}"></div></th>
          <th width="35%" pSortableColumn="groupName">Medical Group<div class="{{sortClass('groupName')}}"></div></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="5%" class="check-column">
            <p-radioButton name="providerid" [inputId]="row.calProviderID" [value]="row.calProviderID" [(ngModel)]="selectedCalProviderID" (click)="onProviderSelected(row)"></p-radioButton>
          </td>
          <td width="40%">
            <div>{{row.name}}</div>
          </td>
          <td width="20%" class="provider-column">
            <div>{{row.calProviderID}}</div>
          </td>
          <td width="35%">
            <div>{{row.groupName}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="report-measurementlabel" *ngIf="selectedCalProviderID != ''">
    <label>Measurement Period for {{displayText}}</label>
  </div>
  <div class="report-filelist" *ngIf="selectedCalProviderID && selectedCalProviderID != ''">
    <p-table tableStyleClass="table-data" #providerList
    [value]="measurementPeriods"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    scrollHeight="400px"
    (onSort)="onSort($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="10%">
            <p-checkbox name='selectunselectall' [(ngModel)]="overAllCheckUnCheck" title="" binary="true"></p-checkbox>
          </th>
          <th width="30%">Measurement Period</th>
          <th width="30%">Status</th>
          <th width="30%">Received Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td width="10%" class="check-column">
            <div>
              <p-checkbox name='{{row.MeasurementPeriod}}' [(ngModel)]="row.selected" title="" binary="true"></p-checkbox>
            </div>
          </td>
          <td width="30%">{{row.measurementPeriodDisplay}}</td>
          <td width="30%">
            <div *ngIf="row.attestationStatus == 'Attested'" style="color:green">{{row.attestationStatus}}</div>
            <div *ngIf="row.attestationStatus == 'Pending'" style="color:orange">{{row.attestationStatus}}</div>            
            <div *ngIf="row.attestationStatus == 'Past Due'" style="color:red">{{row.attestationStatus}}</div>
          </td>
          <td width="30%">{{row.attestationDate}}</td>
        </tr>
      </ng-template>
    </p-table>        
  </div>  
  <div class="report-footer" *ngIf="selectedCalProviderID && selectedCalProviderID != ''">
    <button class="btn btn-primary" type="button" (click)="onBuildReport()" [disabled]='!enableBuildReport'>Download</button>
  </div>
  <!--
  <div class="report-filelist" *ngIf="selectedCalProviderID != '' && fileList && fileList.length > 0">
    <p-dropdown name="providerfiles" id="providerfiles" [options]="fileList" [(ngModel)]="selectedFile">
    </p-dropdown>
    <button class="btn btn-primary" type="button" (click)="onBuildReport()" [disabled]='!enableBuildReport'>Download</button>
  </div>
  -->
</div>
<attestationbhi-dialog #attestation *ngIf="showAttestation" [measurementPeriods]="measurementPeriods" [selectedProvider]="selectedProvider" (submitAttestation)="onSubmitAttestation($event)" (cancelAttestation)="onCancelAttestation()"></attestationbhi-dialog>