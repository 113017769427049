import { DatePipe, DOCUMENT } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { UserProfile } from '@caloptima/authentication';
import { BusyService } from '@caloptima/portal-foundation';
import saveAs from 'file-saver';
import { ReportsMemberSearchComponent } from 'src/app/reports/shared/reports-member-search/reports-member-search.component';
import { CollectionService } from 'src/app/services/collection.service';
import { MemberAWPStatus } from 'src/app/services/models/awp-detail';
import { BHABAReferralAttestation } from 'src/app/services/models/bh-aba-referral-attestation';
import { ProviderDetail } from 'src/app/services/models/provider-detail';
import { ReportMemberDetails } from 'src/app/services/models/reportmemberdetails';
import { ReportType } from 'src/app/services/models/reportype';
import { WellnessDetails } from 'src/app/services/models/wellness-details';
import { ReportsService } from 'src/app/services/reports.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-attestation-bh-aba-referrals-member',
  templateUrl: './attestation-bh-aba-referrals-member.component.html',
  styleUrl: './attestation-bh-aba-referrals-member.component.scss'
})
export class AttestationBhAbaReferralsMemberComponent {
  public userProfile: UserProfile;
  public members:ReportMemberDetails[] = [];
  public displayMembers:ReportMemberDetails[] = [];
  public selectedMember: ReportMemberDetails;
  private sortColumn: string;
  private sortAscending: boolean;
  public totalRecords: number;
  public attestationList: BHABAReferralAttestation[];
  public searchCin: string;
  public searchName: string;
  public collectionId: number;
  public selectedProvider: ProviderDetail;
  public fromProviderTab: boolean;
  public showAttachment: boolean;
  public userFullName: string;
  public wellnessDetails: WellnessDetails;
  public selectedReferral:BHABAReferralAttestation;
  public lastSelectedMemberCin: string;
  public lastMemberSearch: any;
  public selectedCin: ReportMemberDetails;
  public first: number =0;
    

  @ViewChild('memberSearch') memberSearch: ReportsMemberSearchComponent;

  constructor(
    private reportsService: ReportsService,
    private sessionService: SessionService,
    private collectionService: CollectionService,
    private datePipe: DatePipe,
    private busyService: BusyService,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    this.collectionId = this.sessionService.currentPermission.providerCollectionID;
    this.userFullName = '';
    this.fromProviderTab = false;

    this.initialize();
  }

  ngAfterViewInit(): void {

  }

  public setProviderId(providerDetail: ProviderDetail) {
    this.attestationList = [];
    this.selectedProvider = providerDetail;
    this.lastMemberSearch = null;
    this.lastSelectedMemberCin = null;
    this.fromProviderTab = (providerDetail != null);
    this.initialize(true);
  }

  public initialize(resetComponent = false) {
    this.sortColumn = 'memberName';
    this.sortAscending = true;
    this.selectedCin = null;
    this.searchCin = '';
    this.searchName = '';
    this.selectedMember = null;
    this.displayMembers = [];
    
    if (resetComponent) {
      this.memberSearch.resetSearchFilters();
    }

    if(this.selectedProvider) {

      this.reportsService.getMembersForBHABAReferralAttestation(this.collectionId, this.selectedProvider.calProviderID, this.selectedProvider.taxId, ReportType.AWV).subscribe(result=>{

        this.members = result;

        this.totalRecords = this.members.length;

        if(this.members)
          this.displayMembers = this.members.slice();

        let param: any = {
          "field": "memberName",
          "order": 1
        }
        this.onSort(param)

        if(this.lastSelectedMemberCin) {
          let lastselectedMember = this.displayMembers.find(x=>x.cin == this.lastSelectedMemberCin);
          this.lastSelectedMemberCin= null;
          if(lastselectedMember)
            this.onMemberSelected(lastselectedMember);          
        }
      });
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {    
    this.displayMembers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public onMemberSelected(member: ReportMemberDetails): void {
    this.selectedMember = member;    
    this.attestationList = [];
    
    this.reportsService.getBHABAReferralAttestation(null, this.selectedMember.cin, this.selectedMember.providerId).subscribe(result=>{

      if(!this.selectedProvider) {
        this.collectionService.GetProvider2(this.selectedMember.providerId).subscribe(prov=>{

          this.selectedProvider = new ProviderDetail();
          this.selectedProvider.providerID = prov.providerID.toString();
          this.selectedProvider.calProviderID = prov.calProviderId;
          this.selectedProvider.taxId = prov.providerTaxId;
          this.selectedProvider.name = prov.providerName;

          if (!this.selectedMember.providerTaxId) 
            this.selectedMember.providerTaxId = prov.providerTaxId;

          this.attestationList = result;      
        });
      }
      else {
        if (!this.selectedMember.providerTaxId)
          this.selectedMember.providerTaxId = this.selectedProvider.taxId;

        this.attestationList = result;
      }
    });
  }

  public get displayText(): string {
    if(this.selectedMember && this.selectedMember.cin) {

      let memeberDetail: ReportMemberDetails = this.members.find(x=> x.cin === this.selectedMember.cin);

      if(memeberDetail) {
        let s = memeberDetail.memberName;
        return s;
      }
      else {
        return ""
      }
    }
    else {
      return "";
    }
  }

  public get enableBuildReport() : boolean {

    return this.selectedMember != null;
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  public onMemberSearch(search) : void {

    this.lastMemberSearch = search;
    this.selectedMember = null;
    this.attestationList = [];

    if(this.fromProviderTab) {
      if(search.memberId === '') {
        this.displayMembers = this.members.slice();
        this.first = 0;
        this.selectedCin = null;

        let param: any = {
          "field": "memberName",
          "order": 1
        }
        this.onSort(param)        
      }
      else {

        this.displayMembers.splice(0);

        var items = this.members.find(x=>
          x.cin == search.memberId || 
          x.medsId == search.memberId || 
          x.mbi == search.memberId
        );

        this.displayMembers.push(items);
        this.selectedCin = null;
        this.first = 0;
      }      
    }
    else {

      if(search.memberId === '')
      {
          //  Do nothing.
      }
      else {
        this.reportsService.searchMembersForBHABAReferralAttestation(ReportType.AWV, this.collectionId, search.memberName, search.memberId).subscribe(result=>{

          this.members = result;
  
          this.totalRecords = this.members.length;
  
          if(this.members)
            this.displayMembers = this.members.slice();
  
            let param: any = {
              "field": "memberName",
              "order": 1
            }
            this.onSort(param)
  
            if(this.lastSelectedMemberCin) {
              let lastselectedMember = this.displayMembers.find(x=>x.cin == this.lastSelectedMemberCin);
              this.lastSelectedMemberCin = null;
              if(lastselectedMember)
                this.onMemberSelected(lastselectedMember);          
            }
        });
      }
    }
  }  

  public showDialog(referral:BHABAReferralAttestation) : void {

    this.selectedReferral = referral;
    this.showAttachment = true;
  }

  public onCancel() {
    this.showAttachment = false;
  }

  public onSubmit() {
    this.lastSelectedMemberCin = this.selectedMember.cin;  
    if(this.fromProviderTab) {
      var temp = this.selectedCin;
      this.initialize(true);
      this.selectedCin = temp;
    }
    else {
      this.onMemberSearch(this.lastMemberSearch);
    }

    this.showAttachment = false;
  }
}
