import { Address } from '@caloptima/portal-foundation';
import { ProviderType } from './provider-type';
import { CollectionType } from './collection-type';
import { AttestationStatus } from './attestationstatus';
import { TrainingAttestationStatus } from './training-attestation-status';

export class ProviderDetail {
  public providerID: string;
  public providerNPI: string;
  public associationID?: number;
  public calProviderID?: string;
  public collectionID?: number;
  public providerType: ProviderType;
  public providerAddressType: string;
  public collectionType?: CollectionType;
  public collectionTypeKeyValue: string;
  public collectionName: string;
  public taxId?: string;
  public groupId?: string;
  public groupName: string;
  public taxName: string;
  public name: string;
  public effectiveDate?: string;
  public terminationDate?: string;
  public address: Address;
  public addressFull: string;
  public phoneNumber: string;
  public fax?: string;
  public email: string;
  public pcpMemberCount: number;
}

export class PcpInfo {
  pcpId: string;
  pcpName: string;
}


export class ProviderDetailWithAttestation extends ProviderDetail {
    public attestationStatus : AttestationStatus;
}

export class ProviderDetailsWithTrainingAttestation extends ProviderDetail {
    public trainingAttestationStatus: TrainingAttestationStatus;
    public trainingAttestationStatusDisplay: string;
    public trainingDueDate?: Date;
    public attestationDate?: Date;
    public attendees: string;
}
