<p-dialog class="provider-training-attestation-detail-dialog" 
    header="{{headerTitle}}"  
    [style]="{width: '85%', height: '85%'}"  
    [(visible)]="display" 
    [modal]="true" 
    (onHide)="onClose()"
    (onShow)="onShow()" 
    [draggable]="false"
    [baseZIndex]="10000"
>
    <div class="provider-training-attestation-detail-container">
        <div class="attestation-detail-header">
            <div class="report-alert">
                <app-alert-info uniqueID="attestation-detail" subjectMessage="Provider Training Attestation" contentMessage=" -  You must review all of the listed training materials in order to complete the attestation."></app-alert-info>
            </div>
            <h3>{{providerName}}</h3>
            <hr class="header-underline"/>
        </div>
        <div *ngIf="taxDetail" class="attestation-detail-tin-info"> 
            <div class="info-attendees-l">Attendees</div>
            <div class="info-attendees-v">
                <input class="form-control" type="text" name="txtAttendees" id="txtAttendees" 
                  [readonly]="!isPending" [(ngModel)]="attendees" maxlength="2000" width="80%">   
            </div>
            <div class="info-tin-l">TIN</div>
            <div class="info-tin-v">{{taxDetail.providerTaxId}}</div>
            <div class="info-training-date-l">Training Date</div>
            <div class="info-training-date-v">{{taxDetail.attestationDate | date:'MM/dd/yy'}}</div>
            <div class="info-training-status-l">Status</div>
            <div class="info-training-status-v">{{taxDetail.trainingAttestationStatusDisplay}}</div>
        </div>
        <div *ngIf="providerDetail" class="attestation-detail-provider-info">
            <div class="info-attendees-l">Attendees</div>
            <div class="info-attendees-v">
                <input class="form-control" type="text" name="txtAttendees" id="txtAttendees" 
                  [readonly]="!isPending" [(ngModel)]="attendees" maxlength="2000" width="200px">   
            </div>
            <div class="info-provider-l">Provider ID</div>
            <div class="info-provider-v">{{providerDetail.calProviderID}}</div>
            <div class="info-npi-l">NPI</div>
            <div class="info-npi-v">{{providerDetail.providerNPI}}</div>
            <div class="info-tin-l">TIN</div>
            <div class="info-tin-v">{{providerDetail.taxId}}</div>
            <div class="info-training-date-l">Training Date</div>
            <div class="info-training-date-v">{{providerDetail.attestationDate | date:'MM/dd/yy'}}</div>
            <div class="info-training-status-l">Status</div>
            <div class="info-training-status-v">
                <span *ngIf="trainingAttestationStatusDisplay === 'Verified' || trainingAttestationStatusDisplay === 'Submitted'" style="color:green">{{trainingAttestationStatusDisplay}}</span>
                <span *ngIf="trainingAttestationStatusDisplay === 'Pending'" style="color:orange">{{trainingAttestationStatusDisplay}}</span>            
                <span *ngIf="trainingAttestationStatusDisplay === 'Past Due'" style="color:red">{{trainingAttestationStatusDisplay}}</span>
            </div>
        </div>
        <div class="info-network-l">
            <i class="fa fa-circle" aria-hidden="true" class="list-dot"></i><p>Health Network Affiliation</p>
            <div class="info-network-list">
                <ul>
                    <li *ngFor="let network of providerTaxNetwork">
                        <i class="fa fa-circle" aria-hidden="true"></i>
                        <span>&nbsp;{{network.networkId}}</span>	
                    </li>
                </ul>
            </div>
        </div>
        <div class="attestation-detail-training-material">
            <div class="training-info">
                <p>
                    <span>{{resourceGeneralMessage}}</span>
                </p>
                <p>
                    <span>{{resourceDetailMessage}}</span>&nbsp;
                    <a href="https://www.caloptima.org" target="_blank">www.caloptima.org</a>.
                </p>
                <p class="training-header">
                    <span>TRAINING MATERIALS</span>
                    <span *ngIf="isPending" class="required-field-star">*</span>
                </p>
                <div class="training-materials">
                    <p *ngFor="let resource of trainingAttestationResources; let currentIndex = index">
                        <i class="fa fa-arrow-circle-right" aria-hidden="true" id=icon-{{currentIndex}}></i>&nbsp;
                        <a href="javascript:void(0);" (click)="onOpenResource(resource, currentIndex)" title="{{resource.resourceDesc}}" id=link-{{currentIndex}}>{{resource.resourceDesc}}</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="attestation-detail-footer">
            <div class="header">
                <span class="header">ATTESTATION</span>
                <span *ngIf="isPending" class="required-field-star">*</span>
            </div>
            <p>CalOptima Health provided training on the above information and the resources available on CalOptima Health's website.</p>
            <div class="confirm">
                <p-checkbox name="confirm-attestation" id="confirm-attestation" [(ngModel)]="attestationConfirmation" title="I attest that I have completed the Annual Provider Training." binary="true" [disabled]="attestationConfirmation"></p-checkbox>
                <span class="confirm-label">I attest that I have completed the {{attestationName}}.</span>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="attestation-detail-footer-action">
            <span *ngIf="isPending" class="required-field">* Required field</span>
            <div class="action-buttons">
                <button type="button" class="btn btn-secondary btn-cancel" (click)="onCancel()">Cancel</button>                     
                <button *ngIf="isPending" type="button" class="btn btn-primary btn-submit" (click)="onSubmit()" [disabled]="!enableSubmitAttestation">Submit Attestation</button>
            </div>
        </div>
    </p-footer>
</p-dialog>
