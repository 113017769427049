<form class="reports-member-search-container" #memberLookupForm="ngForm" (ngSubmit)="findMembers()"
  autocomplete="off">
  <!-- <div class="title-member-name">
    <label for="memberName">Member Name</label>
  </div>
  <div class="filter-member-name">
        <input class="form-control" type="text" name="txtMemberName" id="txtMemberName" [(ngModel)]="memberName">
  </div> -->
  <div class="title-member-id">
    <label for="memberId">Member ID</label>
  </div>
  <div class="memberIdInput-sub-label">CIN, MEDS ID, or MBI accepted</div>
  <div class="filter-member-id">
    <input class="form-control" type="text"required="false"  name="txtMemberId" id="txtMemberId" [(ngModel)]="memberId"
    maxlength="12" pattern="^\w{4,12}$">
  </div>
  <div class="search-member-button">
    <button id="btnSearchForMember" class="btn-member btn btn-primary" type="submit">
      Search for Members
    </button>
  </div>
</form>
