import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reports-member-search',
  templateUrl: './reports-member-search.component.html',
  styleUrls: ['./reports-member-search.component.scss']
})
export class ReportsMemberSearchComponent implements OnInit {
  public memberName: string;
  public memberId: string;
  @Output() onMemberSearch: EventEmitter<any> = new EventEmitter(null);

  constructor() { }

  ngOnInit() {
  }

  public findMembers() {
    this.onMemberSearch.emit({ memberName: this.memberName, memberId: this.memberId });
  }

  public resetSearchFilters() {
    this.memberName = '';
    this.memberId = '';
  }
}
