import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reports-provider-search',
  templateUrl: './reports-provider-search.component.html',
  styleUrls: ['./reports-provider-search.component.scss']
})
export class ReportsProviderSearchComponent implements OnInit {
  public providerName: string = '';
  public providerId: string = '';
  public providerTin: string = '';
  @Input() dialogType: number;
  @Output() onProviderSearch: EventEmitter<any> = new EventEmitter(null);

  constructor() { }

  ngOnInit() {
  }

  public findProviders() {
    var pname = this.providerName == '' ? null : this.providerName;
    var pid = this.providerId == '' ? null : this.providerId;
    var ptin = this.providerTin == '' ? null : this.providerTin;

    this.onProviderSearch.emit({ providerName: pname, providerId: pid, providerTin: ptin });
  }

  public resetSearchFilters() {
    this.providerName = '';
    this.providerId = '';
    this.providerTin = '';
  }

  public get nameLabel(): string {
    if(this.dialogType && this.dialogType == 2)
      return "Tax Name or Office Name";
    else
      return "Provider Name";
  }
  public get idLabel(): string {
    if(this.dialogType && this.dialogType == 2)
      return "TIN";
    else
      return "Provider ID";
  }  
  public get tinLabel(): string {
     return "Provider TIN";
  }    
  public get requiredName() : boolean {
    if(this.dialogType == 2)
      return false;
    else
      return true;
  }

  public get disableProviderName(): boolean {
    return (this.providerId.trim() != '' || this.providerTin != '')      
  }
  public get disableProviderId(): boolean {
    return (this.providerName.trim() != '' || this.providerTin != '')      
  }  
  public get disableProviderTin(): boolean {
    return (this.providerName.trim() != '' || this.providerId != '')      
  }    
}
