import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subscription } from 'rxjs';
import { AlertInfoComponent } from 'src/app/app-components/alerts/alert-info/alert-info.component';
import { PortalResource } from 'src/app/services/models/portal-resource';
import { ProviderDetailsWithTrainingAttestation } from 'src/app/services/models/provider-detail';
import { ProviderTaxDetailsWithTrainingAttestation, ProviderTaxNetwork } from 'src/app/services/models/provider-tax-details';
import { ProviderTrainingAttestationDetail } from 'src/app/services/models/provider-training-attestation-detail';
import { ProviderTrainingAttestationApplyRequest } from 'src/app/services/models/requests/provider-training-attestation-apply-request';
import { TrainingAttestationStatus } from 'src/app/services/models/training-attestation-status';
import { ReportsService } from 'src/app/services/reports.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-attestation-provider-training-dialog',
  templateUrl: './attestation-provider-training-dialog.component.html',
  styleUrl: './attestation-provider-training-dialog.component.scss'
})
export class AttestationProviderTrainingDialogComponent implements OnInit, OnDestroy {
  public trainingAttestationStatusDisplay: string;
  public providerName: string;
  public attestationConfirmation: boolean = false;
  public attendees: string;
  public providerTaxNetwork: ProviderTaxNetwork[] = [];
  public trainingAttestationResources: PortalResource[] = [];
  public isPending: boolean = true;
  public headerTitle: string = 'Annual Provider Training Attestation Submission';
  public attestationName: string = 'Annual Provider Training';
  public resourceGeneralMessage: string = '';
  public resourceDetailMessage: string = '';

  public get enableSubmitAttestation(): boolean {
    return this.trainingAttestationResources && this.trainingAttestationResources.length 
            && this.trainingAttestationResources.find(r => r.resourceEntity === null) === undefined
            && this.attestationConfirmation;
  }

  private providerTaxId: string;
  private displayDialog: boolean = true;
  private providerTrainingAttestationSubscription: Subscription;
  private currentUser: string;
  
  @Input() taxDetail: ProviderTaxDetailsWithTrainingAttestation = null;
  @Input() providerDetail: ProviderDetailsWithTrainingAttestation = null;
  @Output() onAttestationSubmission = new EventEmitter<any>();

  public get display() : boolean {
    return this.displayDialog
  }

  public set display(value:boolean) {
    this.displayDialog = value;
  }

  constructor (
    private reportService: ReportsService,
    private oAuthService: OAuthService,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    const claims = this.oAuthService.getIdentityClaims();
    this.currentUser = claims['sub'];
  }

  ngOnDestroy(): void {
    if (this.providerTrainingAttestationSubscription != null) {
      this.providerTrainingAttestationSubscription.unsubscribe();
    }
  }

  public onClose(): void {
    this.onAttestationSubmission.emit();
    this.display = false;
  }

  public onShow() {
    if (this.taxDetail && this.providerDetail) {
      this.onAttestationSubmission.emit('error');
      this.display = false;
      throw new Error('only pass either one of the object: taxDetail or providerDetail.');
    }

    if (this.taxDetail) {
      this.providerTaxId = this.taxDetail.providerTaxId;
      this.providerName = this.taxDetail.providerTaxName;
      this.trainingAttestationStatusDisplay = this.taxDetail.trainingAttestationStatusDisplay;
      this.attendees = this.taxDetail.attendees;
      this.isPending = this.taxDetail.trainingAttestationStatusDisplay !== 'Submitted' && !(this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser);
      this.attestationName = 'Annual Provider Training';

      if (!this.taxDetail.attestationDate) {
        this.taxDetail.attestationDate = new Date();
      }
      this.resourceGeneralMessage = 'Per CalOptima Health Policy EE.1103: Provider Network Training: Newly Contracted Provider and annual training must be provided by CalOptima Health and its Health Networks to medical, behavioral health, and Long-Term Services and Support (LTSS) Providers (hereinafter referred to as “Network Providers”), any Provider that interacts with CalOptima Health Members and Subcontractors who serve CalOptima Health’s Members participating in CalOptima Health Medi-Cal, OneCare and Program of All-Inclusive Care for the Elderly (PACE) programs, in accordance with applicable Department of Health Care Services (DHCS) and the Centers for Medicare & Medicaid Services (CMS) requirements.';
      this.resourceDetailMessage = 'Annually, Providers participating with CalOptima Health and Health Networks, have completed, attested, and retained such records of Newly Contracted Provider and Annual Training located on CalOptima Health’s website';
    }
    else {
      this.providerTaxId = this.providerDetail.taxId;
      this.providerName = this.providerDetail.name;
      this.trainingAttestationStatusDisplay = this.providerDetail.trainingAttestationStatusDisplay;
      this.attendees = this.providerDetail.attendees;
      this.isPending = this.providerDetail.trainingAttestationStatusDisplay !== 'Submitted' && !(this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser);
        this.attestationName = 'Initial Provider Training';

      if (!this.providerDetail.attestationDate) {
        this.providerDetail.attestationDate = new Date();
      }

      this.resourceGeneralMessage = 'Per CalOptima Health Policy EE.1103: Provider Network Training: Newly Contracted Provider and annual training must be provided by CalOptima Health and its Health Networks to medical, behavioral health, and Long-Term Services and Support (LTSS) Providers (hereinafter referred to as “Network Providers”), any Provider that interacts with CalOptima Health Members and Subcontractors who serve CalOptima Health’s Members participating in CalOptima Health Medi-Cal, OneCare and Program of All-Inclusive Care for the Elderly (PACE) programs, in accordance with applicable Department of Health Care Services (DHCS) and the Centers for Medicare & Medicaid Services (CMS) requirements.';
      this.resourceDetailMessage = 'Providers participating with CalOptima Health and Health Networks must have completed, attested, and retained such records of Newly Contracted Providers within 30 days of their contract, located on CalOptima Health’s website';
    }

    this.providerTrainingAttestationSubscription = this.reportService.getProviderTrainingAttestationDetail(this.providerTaxId)
    .subscribe(result => {
      this.providerTaxNetwork = result.providerTaxNetwork;
      this.trainingAttestationResources = result.trainingAttestationResources;

      if (!this.isPending) {
        this.attestationConfirmation = true;
        this.headerTitle = this.taxDetail ? 'Annual Provider Training Attestation Detail' : 'Initial Provider Training Attestation Detail';
      }
      else {
        this.headerTitle = this.taxDetail ? 'Annual Provider Training Attestation Submission' : 'Initial Provider Training Attestation Submission';
      }
    });
  }

  public onOpenResource(resource: PortalResource, index: number) {
    const width = document.documentElement.clientWidth * 0.8;
    const height = document.documentElement.clientHeight * 0.8;
    const windowProps = 'resizable=yes, scrollbars=yes, titlebar=yes, width=' + width + ',height=' + height;

    if (this.isPending) {
      let iconEl = document.getElementById('icon-' + index);
      let linkEl = document.getElementById('link-' + index);
      iconEl.style.color = "green";
      linkEl.style.color = "green";
      resource.resourceEntity = "1"; // used this to mark a resource has been viewed with "1"
    }
    window.open(resource.resourceValue, '_blank', windowProps);
  }

  public onCancel() {
    this.onAttestationSubmission.emit('cancel');
    this.display = false;
  }

  public onSubmit() {
    var request: ProviderTrainingAttestationApplyRequest[] = [];
    if (this.taxDetail) {
      request.push({
        providerIdOrTaxId : this.taxDetail.providerTaxId,
        providerNPI: "",
        attestationBy : this.currentUser,
        attendees: this.attendees,
        validationBy : null
      });
      this.reportService.applyProviderTrainingAttestationAnnual(request).subscribe(p => {
        this.taxDetail.trainingAttestationStatus = TrainingAttestationStatus.Submitted;
        this.taxDetail.trainingAttestationStatusDisplay = "Submitted";
        this.taxDetail.attestationDate = new Date();
        this.taxDetail.attendees = this.attendees.trim();
        this.onAttestationSubmission.emit(this.taxDetail);
        this.display = false;
      }, error => {
          console.log(error);
      });
    }
    else {
      request.push({
        providerIdOrTaxId : this.providerDetail.calProviderID,
        providerNPI: this.providerDetail.providerNPI,
        attestationBy : this.currentUser,
        attendees: this.attendees,
        validationBy : null
      });
      this.reportService.applyProviderTrainingAttestationIndividual(request).subscribe(p => {
        this.providerDetail.trainingAttestationStatus = TrainingAttestationStatus.Submitted;
        this.providerDetail.trainingAttestationStatusDisplay = "Submitted";
        this.providerDetail.attestationDate = new Date();
        this.providerDetail.attendees = this.attendees.trim();
        this.onAttestationSubmission.emit(this.providerDetail);
        this.display = false;
      }, error => {
          console.log(error);
      });
    }
  }
}
