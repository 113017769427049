<form class="reports-provider-search-container" #providerLookupForm="ngForm" (ngSubmit)="findProviders()"
  autocomplete="off">
  <div class="title-provider-name" *ngIf="requiredName">
    <label for="providerName">{{nameLabel}}</label>
  </div>
  <div class="filter-provider-name" *ngIf="requiredName">
        <input class="form-control" type="text" name="txtProviderName"  disabled="{{disableProviderName}}" id="txtProviderName" [(ngModel)]="providerName" maxlength="50">
  </div>
  <div class="title-provider-id">
    <label for="provoiderId">{{idLabel}}</label>
  </div>
  <div class="filter-provider-id">
    <input class="form-control" type="text"required="false" disabled="{{disableProviderId}}" name="txtProviderId" id="txtProviderId" [(ngModel)]="providerId"
    maxlength="12" pattern="^\w{4,12}$">
  </div>
  <div class="title-provider-tin">
    <label for="provoiderId">{{tinLabel}}</label>
  </div>
  <div class="filter-provider-tin">
    <input class="form-control" type="text"required="false"  disabled="{{disableProviderTin}}" name="txtProviderTin" id="txtProviderTin" [(ngModel)]="providerTin"
    maxlength="9" pattern="^\w{4,12}$">
  </div>  
  <div class="search-provider-button">
    <button id="btnSearchForProvider" class="btn-provider btn btn-primary" type="submit">
      Search for Provider
    </button>
  </div>
</form>
