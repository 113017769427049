import { Component, OnInit, AfterViewInit, Provider, Input, ViewChild, Inject } from '@angular/core';
import { AuthenticationService, UserProfile } from '@caloptima/authentication';
import { ReportsService } from '../../../services/reports.service';
import { SessionService } from '../../../services/session.service';
import { saveAs } from 'file-saver';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ReportType } from '../../../services/models/reportype';
import { ReportsMemberSearchComponent } from '../../shared/reports-member-search/reports-member-search.component';
import { UiUtility } from '../../../utils/ui-utility';
import { ReportMemberDetails } from '../../../services/models/reportmemberdetails';
import { MemberAWPStatus } from '../../../services/models/awp-detail';
import { WellnessDetails } from '../../../services/models/wellness-details';
import { BusyService } from '../../../services/busy.service';
import { ThisReceiver } from '@angular/compiler';
import { ProviderDetail } from 'src/app/services/models/provider-detail';
import { forkJoin } from 'rxjs';
import { CollectionService } from 'src/app/services/collection.service';
import { ReportsMemberEligibilityRosterComponent } from '../../reports-member-eligibility-roster/reports-member-eligibility-roster.component';
import { ProviderDetail2 } from 'src/app/services/models/provider-detail2';


@Component({
  selector: 'app-awp-member',
  templateUrl: './awp-member.component.html',
  styleUrls: ['./awp-member.component.scss']
})
export class AWPMemberComponent implements OnInit {

  public userProfile: UserProfile;
  public members:ReportMemberDetails[] = [];
  public displayMembers:ReportMemberDetails[] = [];
  public selectedMember: ReportMemberDetails;
  private sortColumn: string;
  private sortAscending: boolean;
  public totalRecords: number;
  public awpList: MemberAWPStatus[];
  public searchCin: string;
  public searchName: string;
  public collectionId: number;
  public selectedProvider: ProviderDetail;
  public fromProviderTab: boolean;
  public showAttachment: boolean;
  public userFullName: string;
  public wellnessDetails: WellnessDetails;
  public selectedMemberAWPStatus:MemberAWPStatus;
  public lastSelectedMemberCin: string;
  public lastMemberSearch: any;
  public selectedCin: string;
  public first: number =0;
    

  @ViewChild('memberSearch') memberSearch: ReportsMemberSearchComponent;

  constructor(
    private reportsService: ReportsService,
    private sessionService: SessionService,
    private collectionService: CollectionService,
    private datePipe: DatePipe,
    private busyService: BusyService,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    this.collectionId = this.sessionService.currentPermission.providerCollectionID;
    this.userFullName = '';
    this.fromProviderTab = false;

    this.initialize();
  }

  ngAfterViewInit(): void {

  }

  public setProviderId(providerDetail: ProviderDetail) {
    this.awpList = [];
    this.selectedProvider = providerDetail;
    this.lastMemberSearch = null;
    this.lastSelectedMemberCin = null;
    this.fromProviderTab = (providerDetail != null);
    this.initialize(true);
  }

  public initialize(resetComponent = false) {
    this.sortColumn = 'memberName';
    this.sortAscending = true;
    this.selectedCin = '';
    this.searchCin = '';
    this.searchName = '';
    this.selectedMember = null;
    this.displayMembers = [];
    
    if (resetComponent) {
      this.memberSearch.resetSearchFilters();
    }

    if(this.selectedProvider) {

      this.reportsService.getMembersForReportsAWP2(this.collectionId, this.selectedProvider.calProviderID, this.selectedProvider.taxId, ReportType.AWV).subscribe(result=>{

        this.members = result;

        this.totalRecords = this.members.length;

        if(this.members)
          this.displayMembers = this.members.slice();

        let param: any = {
          "field": "memberName",
          "order": 1
        }
        this.onSort(param)

        if(this.lastSelectedMemberCin) {
          let lastselectedMember = this.displayMembers.find(x=>x.cin == this.lastSelectedMemberCin);
          this.lastSelectedMemberCin= null;
          if(lastselectedMember)
            this.onMemberSelected(lastselectedMember);          
        }
      });
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onPage(e: any) {
    let s = JSON.stringify(e);
  }

  public onSort(e: any): void {    
    this.displayMembers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
  }

  public onMemberSelected(member: ReportMemberDetails): void {
    this.selectedMember = member;    
    this.awpList = [];
    
    this.reportsService.getReportFilesForMemberAWP2(this.selectedMember.providerId, this.selectedMember.cin,this.selectedMember.grgr_ck,this.selectedMember.providerTaxId, ReportType.AWV).subscribe(result=>{

      if(!this.selectedProvider) {
        this.collectionService.GetProvider2(this.selectedMember.providerId).subscribe(prov=>{

          this.selectedProvider = new ProviderDetail();
          this.selectedProvider.providerID = prov.providerID.toString();
          this.selectedProvider.calProviderID = prov.calProviderId;
          this.selectedProvider.taxId = prov.providerTaxId;
          this.selectedProvider.name = prov.providerName;

          if (!this.selectedMember.providerTaxId) 
            this.selectedMember.providerTaxId = prov.providerTaxId;

          this.awpList = result;      
        });
      }
      else {
        if (!this.selectedMember.providerTaxId)
          this.selectedMember.providerTaxId = this.selectedProvider.taxId;

        this.awpList = result;
      }
    });
  }

  public get displayText(): string {
    if(this.selectedMember && this.selectedMember.cin) {

      let memeberDetail: ReportMemberDetails = this.members.find(x=> x.cin === this.selectedMember.cin);

      if(memeberDetail) {
        let s = memeberDetail.memberName;
        return s;
      }
      else {
        return ""
      }
    }
    else {
      return "";
    }
  }

  public get enableBuildReport() : boolean {

    return this.selectedMember != null;
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  public onMemberSearch(search) : void {

    this.lastMemberSearch = search;
    this.selectedMember = null;
    this.awpList = [];

    if(this.fromProviderTab) {

      if(search.memberId === '') {
        this.displayMembers = this.members.slice();
        this.first = 0;
        this.selectedCin = '';

        let param: any = {
          "field": "memberName",
          "order": 1
        }
        this.onSort(param)        
      }
      else {

        this.displayMembers.splice(0);

        var items = this.members.find(x=>
          x.cin == search.memberId || 
          x.medsId == search.memberId || 
          x.mbi == search.memberId
        );

        this.displayMembers.push(items);
        this.selectedCin = '';
        this.first = 0;
      }      
      
    }
    else {

      this.selectedProvider = null;

      if(search.memberId === '')
      {
          //  Do nothing.
      }
      else {
        this.reportsService.searchMembersForReportsAWP2(ReportType.AWV, this.collectionId, search.memberName, search.memberId).subscribe(result=>{

          this.members = result;  
          this.totalRecords = this.members.length;
  
          if(this.members)
            this.displayMembers = this.members.slice();
  
            let param: any = {
              "field": "memberName",
              "order": 1
            }
            this.onSort(param)
  
            if(this.lastSelectedMemberCin) {
              let lastselectedMember = this.displayMembers.find(x=>x.cin == this.lastSelectedMemberCin);
              this.lastSelectedMemberCin = null;
              if(lastselectedMember)
                this.onMemberSelected(lastselectedMember);          
            }
        });
      }
    }
  }

  public download(awpDetail:MemberAWPStatus) : void {
    if(this.isNewWellness) {
      this.reportsService.downloadUnansweredAWPDetailPdf(awpDetail.documentId).subscribe(result=>{

        var fileName: string = "AWP_PROV_" + this.selectedProvider.taxId + "_" + 
        awpDetail.period.toString() + "_" +
        this.selectedMember.providerId + "_" +
        this.selectedMember.cin;

        this.saveAsFile(result, fileName + ".pdf", 'pdf');
      });
    }
    else {
      this.reportsService.downloadReportFileForMember(this.selectedMember.providerId, this.selectedMember.cin, awpDetail.downloadFileName, ReportType.AWV).subscribe(result=>{
        this.saveAsFile(result, awpDetail.downloadFileName + ".pdf", 'pdf');
      });
    }
  }

  public upload(awpDetail:MemberAWPStatus) : void {

    this.selectedMemberAWPStatus = awpDetail;

    this.wellnessDetails = new WellnessDetails();
    this.wellnessDetails.grgr_ck = this.selectedMember.grgr_ck;
    this.wellnessDetails.memberCIN = this.selectedMember.cin;
    this.wellnessDetails.pcpTIN = this.selectedMember.providerTaxId;
    this.wellnessDetails.providerId = this.selectedMember.providerId;
    this.wellnessDetails.wellnessYear = awpDetail.period;

    this.showAttachment = true;
  }

  public onCancelWellness() {
    this.showAttachment = false;
  }

  public get canShowAttachmentDialog(): boolean {
    if(this.reportsService.newWellnessForm) {      
      if(this.selectedMemberAWPStatus && this.selectedMemberAWPStatus.usedDocument)
        return true;
      else
        return false;
    }
    else {
      return true;
    }
  }

  public get canShowDetailsDialog(): boolean {
    return !this.canShowAttachmentDialog;
  }  

  public onApplyWellness(selectedMemberAWPStatus: MemberAWPStatus) {
    this.lastSelectedMemberCin = this.selectedMember.cin;

    if(this.selectedProvider) {
      var temp = this.selectedCin;
      this.initialize(true);
      this.selectedCin = temp;
    }
    else {
      this.onMemberSearch(this.lastMemberSearch);
    }

    this.showAttachment = false;
  }

  public get isNewWellness(): boolean {
    return this.reportsService.newWellnessForm;
  }

  public uploadCaption(item:MemberAWPStatus) : string {
    let currentYear: Date = new Date();
    let applicable:boolean = true;

    if(item && item.period < currentYear.getFullYear() - 1) {
      applicable = false;
    }
    else if(item && item.period == currentYear.getFullYear() - 1 && currentYear.getMonth() > 0) {
      applicable = false;
    }

    if(item.status.trim().toUpperCase() === "SUBMITTED"){
      return "VIEW";
    }      
    else {
      if(applicable)
        return this.isNewWellness ? "VIEW" : "UPLOAD";
      else
        return "";
    }      
  }

  public downloadCaption(item: MemberAWPStatus) : string {
    let currentYear: Date = new Date();
    let applicable:boolean = true;

    if(item && item.period < currentYear.getFullYear() - 1) {
      applicable = false;
    }
    else if(item && item.period == currentYear.getFullYear() - 1 && currentYear.getMonth() > 0) {
      applicable = false;
    }

    return (applicable) ? "DOWNLOAD" : "";   
  }

  public pendingCaption(item: MemberAWPStatus) : string {
    let currentYear: Date = new Date();
    let applicable:boolean = true;

    if(item && item.period < currentYear.getFullYear() - 1) {
      applicable = false;
    }
    else if(item && item.period == currentYear.getFullYear() - 1 && currentYear.getMonth() > 0) {
      applicable = false;
    }

    return (applicable) ? "Pending" : "Expired";   
  }  
}
